import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Logo from "../images/logo.svg";


export default function Header({ embed }) {

    const [visible, setVisible] = useState(!embed);
    const [menuVisible, setMenuVisible] = useState(false);
    const [tId, setTId] = useState();

    useEffect(() => {
        if (embed) {
            const scrollFunction = () =>{
                const a = window.scrollY;
                if ( a >= 100) {
                    setVisible(true);
                } else {
                    setVisible(false);
                }
            };
            window.addEventListener("scroll", scrollFunction);
            return function cleanup() {
                window.removeEventListener("scroll", scrollFunction);
            };
        }
    }, [embed]);

    /* Menu handling */
    const showMenu = () => {
        setMenuVisible(true);
    };
    const hideMenu = () => {
        setMenuVisible(false);
    };
    const toggleMenu = () => {
        if (menuVisible) {
            hideMenu();
        } else {
            showMenu();
        }
    };
    // Keyboard Accesibility
    const onBlur = () => {
        setTId(setTimeout(() => {
            hideMenu();
            setTId(null);
        }));
    };
    const onFocus = () => {
        if (tId) {
            clearTimeout(tId);
            setTId(null);
        }
    };
    const handleKeyPress = (ev) => {
        if (ev.code === "Enter" || ev.code === "Space") {
            toggleMenu();
        }
    };

    /* End menu handling */

    return (
        <header 
            id="header" 
            className={(
                embed ? (
                    visible ? "reveal" : "alt"
                ) : ""
            ) + (
                menuVisible ? " popup" : "" 
            )}
            onBlur={onBlur}
            onFocus={onFocus}
        >
            <span className="menubuttons">
                <span
                    className="menubtn"
                    role="button"
                    aria-label="Menu"
                    aria-haspopup="true"
                    aria-expanded={menuVisible}
                    tabIndex="0"
                    onClick={toggleMenu}
                    onKeyPress={handleKeyPress}
                >
                    <span className="icon solid fa-bars"></span>
                </span>
                <h1 id="logo">
                    <Link 
                        to="/"
                        onClick={hideMenu}
                    >
                        <img alt="eduFlux" src={Logo} />
                    </Link>
                </h1>
            </span>
            <nav 
                id="nav" 
            >
                <ul>
                    <li>
                        <Link 
                            to="/" 
                            className="icon solid fa-home" 
                            onClick={hideMenu}
                        >
                            <span className="navigation">Home</span>
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to="/datenschutz" 
                            className="icon solid fa-handshake" 
                            onClick={hideMenu}
                        >
                            <span className="navigation">Datenschutz</span>
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to="/impressum" 
                            className="icon solid fa-envelope" 
                            onClick={hideMenu}
                        >
                            <span className="navigation">Impressum</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

Header.propTypes = {
    embed: PropTypes.bool
};

Header.defaultProps = {
    embed: false
};
