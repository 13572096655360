import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import NoSidebar from "./NoSidebar";
import Home from "./Home";

import "../styles/sass/main.scss";
import "@fontsource/open-sans";

//Generate Layout Mapping Function....
function Layout({ children, ...props}) {
    const type = _.get(props, "pageContext.frontmatter.template", "default");
    if (type == "home") {
        //console.log("Render home", children);
        return <Home>{children}</Home>;
    } else {
        //console.log("Render Default", children);
        const { 
            title, 
            description 
        } = _.get(props, "pageContext.frontmatter", {});
        return <NoSidebar title={title} text={description}>{children}</NoSidebar>;
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    props: PropTypes.object,
    type: PropTypes.oneOf([
        "home",
        "default",
        "no-sidebar"
    ])
};

Layout.defaultProps = {
    type: "default",
    props: {}
};

export function wrapPageElement({element, props}) {
    return <Layout {...props}>{element}</Layout>;
}
