import React from "react";
import PropTypes from "prop-types";

export default function BoxSection({children, title, text, variant, extraClasses}) {
    return (
        <section id="main" className={`wrapper style1 ${extraClasses}`}>
            <div className={`container box ${ variant } bordered`}>
                {(title || text) ? (
                    <header className="major special">
                        {title ? <h2>{title}</h2> : null}
                        {text ? <div>{text}</div> : null}
                    </header>
                ) : null}
                {children}
            </div>
        </section>
    );
}

BoxSection.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.node,
    text: PropTypes.node,
    variant: PropTypes.oneOf([
        "large",
        "alt"
    ]),
    extraClasses: PropTypes.string
};

BoxSection.defaultProps = {
    title: null,
    text: null,
    variant: "large",
    extraClasses: ""
};
