import React from "react";
import News from "./News";

const Footer = () => {
    const currentYear = (new Date()).getFullYear();
    return (
        <div id="footer">
            <div className="container">
                <div className="row gtr-200 gtr-uniform">
                    <section className="col-4 col-12-medium col-12-xsmall footer-info">
                        <h2>Fehlzeitenerfassung</h2>
                        <p>
                            Die Fehlzeitenerfassung ist ein Tool zur Erfassung
                            und Analyse von Fehlzeiten, um Schulabsentismus zu verhindern.
                        </p>
                        <ul className="actions">
                            <li>
                                {/* eslint-disable max-len */}
                                <a href="https://fehlzeitenerfassung.de/" className="button alt">Mehr erfahren</a>
                                {/* eslint-disable max-len */}
                            </li>
                        </ul>
                    </section>
                    <section className="col-4 col-6-medium col-12-xsmall">
                        <h3>Aktuelles</h3>
                        <ul className="updates">
                            <News />
                        </ul>
                    </section>
                    <section className="col-4 col-6-medium col-12-xsmall">
                        <h3>Kontakt</h3>
                        <ul className="labeled-icons">
                            <li>
                                <h4 className="icon solid fa-home">
                                    <span className="label">Adresse</span>
                                </h4>
                                eduFlux GmbH<br />
                                Friedrich-Ebert-Straße 55<br />
                                42103 Wuppertal
                            </li>
                            <li>
                                <h4 className="icon solid fa-phone">
                                    <span className="label">Tel</span>
                                </h4>
                                <a href="tel:+49-2336-9242360">02336-9242360</a>
                            </li>
                            <li>
                                <h4 className="icon solid fa-envelope">
                                    <span className="label">Email</span>
                                </h4>
                                <a href="mailto:info@eduflux.de">info@eduflux.de</a>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
            <div className="copyright">
                &copy; 2020-{currentYear} eduFlux GmbH. Alle Rechte vorbehalten.
            </div>
        </div>
    );
};

export default Footer;
