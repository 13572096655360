import React from "react";
import PropTypes from "prop-types";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BoxSection from "../components/BoxSection";

export default function NoSidebar({ children, title, text }) {
    return (
        <div className="layout landing is-preload">
            <Header />
            <BoxSection title={title} text={text}>
                {children}
            </BoxSection>
            <Footer />
        </div>
    );
}

NoSidebar.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.node,
    text: PropTypes.node
};

NoSidebar.defaultProps = {
    title: null,
    text: null
};
