import React from "react";
import PropTypes from "prop-types";

import "../styles/sass/main.scss";

import "@fontsource/open-sans";

import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Home({ children }) {
    return (
        <div className="layout landing is-preload">
            <Header embed={true}/>
            {children}
            <Footer />
        </div>
    );
}

Home.propTypes = {
    children: PropTypes.node.isRequired
};
