import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const News = () => {
    const site = useStaticQuery(
        graphql`
            query {
                allMdx(
                    filter: {frontmatter: {tag: {eq: "news"}}}, 
                    sort: {fields: frontmatter___date}
                ) {
                    nodes {
                        frontmatter {
                            title
                            tag
                            date
                            description
                            path
                        }
                        excerpt(pruneLength: 40)
                    }
                }
            }
        `
    );
    const nodes = site.allMdx.nodes;
    
    // Change entryCount to change the number of news shown in footer (last <entryCount> news)
    const entryCount = 3;
    const lastNodes = nodes.slice(nodes.length - entryCount, nodes.length);
    return (
        <>
            {lastNodes.map((node, i) => {
                const newsDate = (new Date(node.frontmatter.date)).toLocaleDateString("de-DE");
                return (
                    <li key={i}>
                        <Link to={`/${node.frontmatter.path}`}>
                            <h5>{node.frontmatter.title}</h5>
                            <p>{
                                node.frontmatter.description ? 
                                    node.frontmatter.description 
                                    : node.excerpt
                            }</p>
                            <span className="timestamp">
                                Neuigkeit vom {newsDate}
                            </span>
                        </Link>
                    </li>
                );
            })}
        </>
    );
};

export default News;
